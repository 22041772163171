<template>
  <div
    class="Login"
    fluid
  >
    <div class="has-card">
      <v-card
        flat
        color="transparent"
        :loading="cargando"
        :disabled="cargando"
      >
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
            md="5"
          >
            <div
              style="padding:24px;margin-left:auto;margin-right:auto;"
              class="img-center"
            >
              <!-- <v-img class="d-none d-sm-flex" style="" src="@/assets/avatars/login/login-large.png" /> <img class="d-flex d-sm-none" style="width:auto;max-width:100%;height:100%;max-height:200px;margin-left:auto;margin-right:auto" src="@/assets/avatars/login/login-small.png" /> -->
              <v-img src="@/assets/logo.png" />
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="7"
          >
            <v-card-title v-text="'¡Bienvenido a Dental Master!'" />
            <v-card-subtitle v-text="'Ingresa tu telefono o correo electrónico junto a tu contraseña para acceder a tu sesión'" />
            <v-card-text>
              <v-text-field
                elevation="0"
                v-model="usuario"
                placeholder="Teléfono o correo electrónico"
                prepend-inner-icon="person_outline"
                @keyup.native.enter="$event=>_doLogin('login')"
                filled
                large
                type="email"
                :disabled="cargando"
              />
              <!-- <v-text-field
                v-model="password"
                placeholder="Contraseña"
                prepend-inner-icon="vpn_key"
                type="password"
                @keyup.native.enter="$event=>_doLogin('login')"
                filled
                password-reveal
                large
                :disabled="cargando"
              /> -->
              <v-btn
                :loading="cargando"
                block
                color="primary"
                dark
                depressed
                large
                v-text="'Iniciar sesión'"
                @click="$event=>_doLogin('login')"
              />
              <!-- <v-facebook-login
                :app-id="fbid"
                @sdk-init="handleSdkInit"
                :login-options="loginOptions"
                :options="options"
                @login="_getFbUserData"
                style="width:100%"
                class="mt12"
              /> -->
              <v-btn
                block
                class="mt12"
                color="primary"
                light
                depressed
                large
                text
                :loading="cargando"
                outlined
                v-text="'Registrarme'"
                to="/registro"
              />
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import forms from "@/mixins/forms.js";
import fblogin from "@/mixins/fblogin.js";
export default {
  mixins: [forms, fblogin],
  name: "Login",
  created() {
    this.$emit("appClasses", "");
  },
  beforeDestroy() {
    this.$emit("appClasses", null);
  },
};
</script>
<style lang="scss">
</style>